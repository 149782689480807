.jumbo {
 position: relative;
 height: auto;
 width: 100%;
 overflow: hidden;
 background-color: $brand-2;
}

.jumbo-content {
 display: flex;
 flex: 1;
 text-align: center;
 height: 100%;
 padding: 40px;
 

 .text-wrap {
  align-self: center;
  max-width: 700px;
  color: #fff;
  margin: auto;
 }
}

.jumbo-title {
 display: block;
 font-family: $font-bold;
 font-size: 2em;
 margin-top: 0.6em;
}

.jumbo svg {
 position: absolute;
 top: 0; right: 1em;
 color: #fff;
 font-size: 60px;
}

@media screen and (min-width: 700px) {
 .jumbo {
  height: 180px;
 }
 .jumbo-content {
  padding: 0px;
 }
}
