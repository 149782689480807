#back-top {
 position: fixed;
 display: block;
 right: 20px; bottom: 20px;
 color: $brand-4;
 opacity: 0.8;
 z-index: 40;
 font-size: 40px;

 &:hover, &:focus {
  color: $brand-1;
  opacity: 1;
 }
}