section.related {
 padding-top: 3em;

 h2 {
  text-align: center;
  font-size: 1.6em;
  font-family: $font-bold;
 }
}

.related-list {
 display: flex;
 margin-top: 2em;
 flex-wrap: wrap;
 justify-content: space-between;
}

.related-item {
 flex: 0 0 220px;
 height: 300px;
 color: #fff;
 border-radius: 10px;
 padding: 1.2em;
 margin: 0 auto 2em auto;

 display: flex;
 flex-direction: column;

 .header {
  font-family: $font-bold;
  text-transform: uppercase;
  font-size: 0.8em;
 }
 h3 {
  margin-top: 1em;
  line-height: 1.4em;
  font-family: $font-regular;
 }
 .cta {
  margin-top: auto; // to get flex left space

  display: block;
  position: relative;
  text-align: right;
  color: #fff;
  padding-right: 1.4em;

  &:after {
   $arrow-size: 5px;

   position: absolute;
   content: "";
   width: 0; 
   height: 0; 
   border-top: #{$arrow-size} solid transparent;
   border-bottom: #{$arrow-size} solid transparent;
   border-right: #{$arrow-size} solid transparent;
   border-left: calc(#{$arrow-size} + 3px) solid #fff;
   top: 2.8px;
   right: 0em;
  }
 }

 &.color1 {background-color: $brand-1}
 &.color2 {background-color: $brand-2}
 &.color3 {background-color: $brand-3}
 &.color4 {background-color: $brand-4}

 &:hover, &:focus {
  text-decoration: none;
  background-color: $gray-dark;

  .cta {
   color: $brand-1;
   &:after {border-left-color: $brand-1}
  }
 }
}

@media screen and (min-width: 600px) {
 .related-list {
  justify-content: flex-start;
 }
 .related-item {
  margin: 0 2em 2em 0;
 }
}