.contact-layout {
 text-align: center;

 .form-wrap {
  width: 80%;
  margin: 4em auto 6em auto;
  padding-top: 0;
 
  button {
   background-color: $brand-1;
   font-size: 1em;
   color: #fff;
   border: none;
   border-radius: 8px;
   padding: 1em 2em 0.8em 2em;
   font-family: $font-bold;
   cursor: pointer;
 
   &:hover, &:focus {
    background-color: $gray-medium;
   }
  }
 }

 .input-wrap {
  margin: 40px 0;
 
  label {
   display: block;
   text-align: left;
   
   span {
    color: $brand-1;
   }
  }
  input, textarea, select {
   display: block;
   margin-top: 10px;
   padding: 11px 10px 10px;
   border: 1px solid $gray-medium;
   border-radius: 4px;
   font-size: 1em;
   color: $gray-dark;
   font-family: $font-bold;
   width: 100%;
   background-color: transparent;
  }
  textarea {font-family: $font-bold}
 }
}


@media screen and (min-width: 700px) {
 .contact-layout {
  .form-wrap {
   width: 40%;
  }
 }
}