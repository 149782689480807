/* CONFIG */
$max-width-header: 1200px;

/* RULES */
.main-header {
 width: 100%;
 height: $header-height;
 width: 100%;
 background-color: #fff;

}

.header-wrap {
 display: flex;
 width: 100%;
 max-width: $max-width-header;
 margin: auto;
 padding: 0 30px 0 20px;
}

.logo-wrap {
 flex: 1;
 text-align: left;
 line-height: 4em;

 a {
  color: $gray-dark;

  &:hover, &:focus {
   color: $brand-1;
   text-decoration: none;

   svg {
    color: $brand-1;
   }
  }
 }

 svg {
  vertical-align: middle;
  font-size: 50px;
  color: $gray-dark;
 }
}

.navbar {
 display: none;
 flex: 1;
 line-height: 4em;
 text-align: right;

 nav {
  a {
   margin-left: 2.5em;
   color: $gray-dark;

   &:hover, &:focus {
    color: $brand-1;
    text-decoration: none;
   }
  }
  a.active {
   color: $brand-1;
  }
 }
}

.main-header.home {
 position: absolute;
 top: 0;
 left: 0;
 z-index: 3;
 background-color: transparent;

 .logo-wrap {
  svg {
   color: #fff;
   font-size: 60px;
   margin-top: 10px;
   margin-left: -10px;

   &:hover, &:focus {
    color: $gray-medium;
   }
  }
 }

 a {
  color: #fff;

  &:hover, &:focus {
   color: $gray-medium;
  }
 }
}

@media screen and (min-width: 600px) {
 .navbar {
  display: block;
 }
}