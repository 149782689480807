/* -- Slideout Sidebar -- */
/* CONFIG */
$mobile-menu-width: 300px;

.slideout-sidebar {
 position: fixed;
 top: 0;
 left: calc(-#{$mobile-menu-width});
 z-index: 200;
 height: 100%;
 padding: 40px 30px 30px 50px;
 background-color: #fff; background-color: $gray-light;
 transition: all 400ms ease-in-out;
 border-right: solid #000 1px;
 font-size: 15px;

 ul {max-width: 300px}
}

.mobnav {
 a {
  margin-top: 20px;
  display: block;
  text-decoration: none;
  line-height: 2em;
  color: $gray-dark !important;

  &:hover, &:focus {
   color: $brand-1;
  }
 }
 a.active {
  color: $brand-1;
 }
}

// .mob-accordion {
//  width: 100%;
//  overflow: hidden;

//  label {line-height: 2em;}

//  input {
//   position: absolute;
//   opacity: 0;
//   z-index: -1;
//  }
// }

// .mob-accordion-label {
//  display: flex;
//  justify-content: space-between;
//  align-items: center;

//  span {flex: 1}

//  #chevron-right {
//   flex: 0 0 10%;
//   font-size: 16px;
//   transition: all 300ms ease-in-out;
//  }
// }

// .mob-accordion-content {
//  max-height: 0;
//  padding: 0 1em;
//  transition: all .35s ease-in-out;
//  text-align: left;
//  line-height: 1.6em;

//  a {
//   display: block;
//   margin: 0;
//   line-height: 2.4em;
//  }
// }
// .mob-accordion input:checked ~ .mob-accordion-content {
//  max-height: 100vh;
//  padding: 1em 1em;
// }
// .mob-accordion input:checked + .mob-accordion-label #chevron-right {
//  transform: rotate(90deg);
// }

/* -- Menu Icon -- */
#menu-toggle {display: none}

.menu-icon {
 position: absolute;
 top: 16px;
 right: 16px;
 font-size: 24px;
 z-index: 25000;
}

.menu-icon.home {
 color: #fff;
}

@media screen and (min-width: 600px) {
 .menu-icon {display: none}
}

/*- WHEN ICON IS CLICKED -*/
#t, #m, #b {transition: 0.4s ease-in-out;}
#menu-toggle:checked { 
 & ~ .slideout-sidebar {
 left: 0px;
 width: 100%;
 }
 & ~ .menu-icon {
  #t {transform: rotate(45deg)}
  #m {transform: rotate(-45deg) translateX(-50%) translateY(15%)}
  #b {transform: translateY(100%)}
 }
}

@media screen and (min-width: 400px) {
 #menu-toggle:checked ~ .slideout-sidebar {width: $mobile-menu-width}
}