// IMPORTS

// CONFIG
$max-width-home: 1200px;
$trans-time: 0.18s;

// RULES

// HERO 

.home-layout{
 max-width: 100%;
 padding: 0;
}

.jumbo-home {
 display: flex;
 height: auto;
 justify-content: center;
 background-color: $brand-1;
 padding: 4em 20px;

 background: linear-gradient(313deg, #f9870a, #f99383, #9e3a80);
 background-size: 600% 600%;

 animation: AnimationName 12s ease infinite;
}

@keyframes AnimationName {
 0%{background-position:0% 86%}
 50%{background-position:100% 15%}
 100%{background-position:0% 86%}
}

.jumbo-home-content {
 width: 100%;
 max-width: $max-width-home;
 align-self: center;
 color: #fff;
 margin-top: $header-height;

 h1 {
  font-size: 30px;
  font-family: $font-light;
  max-width: 800px;
 }
}

.jumbo-home-title {
 margin: 0;
 font-size: 2em;
 font-family: $font-bold;
}

.jumbo-home-desc {
 margin: 20px 0 40px;
 font-size: 42px;
 line-height: 1.1em;
 text-align: left;
 font-family: $font-bold;
 max-width: 800px;
}

.cta {
 border-radius: 6px;
 padding: .9em 2em .5em;
 font-size: 16px;
 display: flex;
 align-items: center;
 max-width: 250px;
}

@keyframes AnimateCTA {
 0%{
  box-shadow:10px 10px 0px 0px rgba($color: #662653, $alpha: 0.6);
  color: #662653;
 }
 25%{
  box-shadow:10px 10px 0px 0px rgba($color: #c56905, $alpha: 0.6);
  color: #c56905;
 }
 50%{
  box-shadow:10px 10px 0px 0px rgba($color: #f7402b, $alpha: 0.6);
  color: #f7402b;
 }
 75%{
  box-shadow:10px 10px 0px 0px rgba($color: #c56905, $alpha: 0.6);
  color: #c56905;
 }
 100%{
  box-shadow:10px 10px 0px 0px rgba($color: #662653, $alpha: 0.6);
  color: #662653;
 }
}

.home-hero-cta {
 display: flex;

 .cta {
  &:first-child {
   margin-right: 40px;
  }

  &:hover {
   cursor: pointer;
  }

  &.-primary {
   box-shadow: 10px 10px 0px 0px #c56905;
   animation: AnimateCTA 24s ease infinite;
   background-color: white;

   &:hover {
    background-color: darken($color: #fff, $amount: 5);
   }
  }

  a {
   display: inline-block;
  }
 }

}

// Trust Us

.home-trust-us {
 margin: 115px auto 85px;
}

.home-h2 {
 h2 {
  font-size: 32px;
  text-align: center;
  padding: 0 15px;
 }
}

.home-subtitle {
 display: flex;
 justify-content: center;
 margin-top: 20px;

 p {
  max-width: 950px;
  padding: 0 15px;
  font-size: 28px;
  text-align: center;
  font-family: $font-light;
 }
}

.home-wrap-logos {
 max-width: 1200px;
 display: flex;
 flex-direction: column;
 align-items: center;
 flex-wrap: wrap;
 margin: 60px auto 0;

.home-frame-logo {
 width: 300px;
 height: 100px;
 // background-color: transparent;
 margin-bottom: 30px;
 display: grid;
 place-items: center;
 overflow: hidden;
}

img {
 padding: 15px 30px;
 max-width: 300px;
 height: auto;
 max-height: 100%;
}
}

// Référencement Naturel

.home-section {
 padding: 20px 0 40px;

 &.-seo {
  background-color: rgba($color: $brand-2, $alpha: 1);
 }

 &.-ads {
  background-color: $brand-3;
 }

 &.-strategy {
  background-color: $brand-4;
 }

 &.-white {
  .home-h2{
   h2{color: $gray-dark;}
  }
  .home-subtitle{
   p{color: $gray-dark;}
  }
 }

 .home-h2{
  h2{color: white;}
 }
 .home-subtitle{
  p{color: white;}
 }
}


.home-services-cta {
 text-align: center;
 margin: 40px auto 50px;
}

.cta {
 &.-white {
  border: 1px solid white;
  background-color: white;
  display: inline-block;
  color: $gray-dark;

  &:hover {
   color: white;
   background-color: $gray-dark;
   border-color: $gray-dark;
   transition: $trans-time ease-in-out;
  }
 }

 &.-black {
  display: inline-block;
  background-color: $gray-dark;
  color: white;
  border: 1px solid $gray-dark;

  &:hover {
   color: $brand-1;
   transition: $trans-time ease-in-out;
  }
 }

 &.-jaune {
  display: inline-block;
  background-color: $brand-1;
  color: white;

  &:hover {
   color: white;
   background-color: $gray-dark;
   border-color: $gray-dark;
   transition: $trans-time ease-in-out;
  }
 }
}

// Contact

.-contact {
 .home-h2{
  display: flex;
  justify-content: center;
  width: 100%;
  // padding: 50px 0 40px;

  h2{
   display: inline-block;
   color: $gray-dark;
   border-bottom: 4px solid $brand-1;
   padding: 0px;
  }
 }
 .home-subtitle{
  p{color: $gray-dark;}
 }
}

@media screen and (min-width: 450px) {
 .jumbo-home {
  padding: 3em 2em;
 }

 .jumbo-home-title {
  font-size: 3em;
 }

 .jumbo-home-desc {
  font-size: 1.2em;
 }
 .jumbo-home-cta {
  margin-top: 3em;
  justify-content: flex-end;
  a {
   flex: 0 0 280px;
   margin: 0 0 1em 2em;
   font-size: 1.1em;
  }
 }
}

@media screen and (min-width: 800px) {
 .jumbo-home {
  padding: 0 4em;
  height: calc(100vh - #{$header-height});
 }

 .jumbo-home-title {
  font-size: 4em;
 }

 .jumbo-home-desc {
  margin: 30px 0 30px;
  font-size: 60px;
  max-width: 1100px;
 }

 .home-h2 {
  h2 {
   font-size: 52px;
   text-align: center;
   padding: 0 72px;
  }
 }

 .cta {
  border-radius: 6px;
  padding: .7em 2.4em .4em;
  font-size: 28px;
  display: flex;
  align-items: center;
  max-width: 450px;
 }
 
 .home-wrap-logos {
  flex-direction: row;
  justify-content: center;

 }
}

@media screen and (min-width: 1100px) {
 .jumbo-home-desc {
  font-size: 80px;
 }
}