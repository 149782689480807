
/* TYPOGRAPHY */
@font-face {
 font-family: "Neuzeit Grotesk Regular";
 src: url("/fonts/NeuzeitGro-Reg.otf") format("opentype");
}
@font-face {
 font-family: "Neuzeit Grotesk Black";
 src: url("/fonts/NeuzeitGro-Bla.otf") format("opentype");
}
@font-face {
 font-family: "Neuzeit Grotesk Light";
 src: url("/fonts/NeuzeitGro-Lig.otf") format("opentype");
}
@font-face {
 font-family: "Neuzeit Grotesk Bold";
 src: url("/fonts/NeuzeitGro-Bol.otf") format("opentype");
}

$font-regular: 'Neuzeit Grotesk Regular', sans-serif;
$font-light: 'Neuzeit Grotesk Light', sans-serif;
$font-bold: 'Neuzeit Grotesk Bold', sans-serif;
$font-black: 'Neuzeit Grotesk Black', sans-serif;

$font-size-base: 18px;
// $font-size-base-desktop: 18px;

/* COLORS */
$brand-1: #f9870a;
$brand-1-p20: darken($brand-1, 20%);
$brand-1-m45: lighten($brand-1, 45%);

$brand-2: #9e3a80;
$brand-3: #f97060;
$brand-4: #a6cfd1;

$gray-dark: #383838;
$gray-medium: #e2cfc8;
$gray-light: #dedede;


/* SPACING */
$max-width-normal-section: 1200px;

/* COMPONENTS */
$header-height: 4em;