/* CONFIG */
$max-width-breadcrumbs: 1200px;

.breadcrumbs {
 background-color: #fff;
 border-bottom: 1px solid $brand-1;
 
 nav {
  max-width: $max-width-breadcrumbs;
  margin: auto;
  padding: 20px 1em 16px 1em;
  font-size: 0.8em;
  line-height: 1.2em;

  a {
   display: inline-block;
   margin-right: 2em;

   &:hover, &:focus {
    text-decoration: none;
    color: $brand-1-p20;
   }
  }
  span {
   display: inline-block;
   position: relative;
   font-family: $font-bold;
   cursor: default;

   &:before {
    $arrow-size: 4px;

    position: absolute;
    content: "";
    width: 0; 
    height: 0; 
    border-top: #{$arrow-size} solid transparent;
    border-bottom: #{$arrow-size} solid transparent;
    border-right: #{$arrow-size} solid transparent;
    border-left: calc(#{$arrow-size} + 3px) solid $gray-dark;
    top: 3px;
    left: -1em;
   }
  }

  span.first {
   margin-left: 1em;
  }
 }
}