/* IMPORTS */

/* CONFIG */
$max-width-list: 1000px;

.list-layout {
 background-color: #fff;
 margin-top: 0px;
 padding: 0;
 max-width: 100%;
}

.list-content {
 display: block;
}

.list-content aside {
 background-color: $brand-1-m45;
 padding: 2em 2em 1em 1em;
}

.list-content article {
 padding: 1em 1em 3em 1em;

 .overview {
  text-transform: uppercase;
  font-family: $font-bold;
  color: $brand-1;
  font-size: 1.2em;
  margin-bottom: 1em;
 }
}

.list-item {
 display: block;
 margin-top: 3em;
 background-color: $gray-light;
 color: $gray-dark;
 padding: 2.2em 2em 1.6em 2em;

 h2 {
  margin-top: 0.6em;
  font-size: 2em;
 }
 .description {
  font-family: $font-regular;
 }
 .cta {
  display: block;
  margin-top: 1.4em;
  color: $brand-1;
  text-align: right;
 }

 &:hover, &:focus {
  text-decoration: none;
  background-color: $gray-medium;
  .cta {color: $brand-1-p20}
 }
}

@media screen and (min-width: 700px) {
 .list-content {
  display: flex;
  max-width: $max-width-normal-section;
  margin: 0px auto 0px auto;
 
  aside {
   flex: 0 0 240px;
  }
  article {
   flex: 1;
  }
 }
 .list-content article {
  padding: 2em 6em 5em 2em;
 }
}