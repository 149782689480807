footer {
 background-color: $gray-dark;
 padding: 2em 2em 1em 2em;
 color: #fff;
}

.footer-first-line {
 display: flex;
 flex-direction: column;
}

.col-f1, .col-f2, .col-f3, .col-f4 {
 flex: 1;
 margin-bottom: 2em;
}

.footer-logo {
 svg {
  font-size: 60px;
 }
 p {
  font-family: $font-bold;
  max-width: 100px;
  line-height: 1.2em;
  margin-top: 0.4em;
 }
}

.footer-title {
 font-size: 20px;
 font-family: $font-bold;
 margin-bottom: 8px;
 line-height: 1.2em;
}

.footer-nav {
 a {
  display: block;
  color: #fff;
  font-size: 16px;
  line-height: 1.8em;
  font-family: $font-light;

  &:hover, &:focus {
   text-decoration: none;
   color: $brand-1;
  }
  &:first-of-type {margin-left: 0;}
 }
}

.contact-wrap {
 text-align: left;
}
.contact-icons {
 a {
  margin-left: 2px;
  color: $brand-3;
  font-size: 24px;

  &:hover, &:focus {
   color: $brand-4;
  }
 }
}

.footer-second-line {
 display: flex;
 flex-direction: column-reverse;
 width: 100%;
 border-top: 1px solid #fff;
 padding-top: 0.4em;

 & > :first-child {
  flex: 1;
 }
}

.copyright {
 margin-top: 2em;
 font-size: 12px;
 font-family: $font-bold;
 cursor: default;
}

.footer-other-links {
 a {
  line-height: 2em;
  display: block;
  margin-left: 0em;
  color: #fff;
  font-size: 12px;

  &:hover, &:focus {
   text-decoration: none;
   color: $brand-1;
  }
 }
}

@media screen and (min-width: 700px) {
 .footer-first-line {
  flex-direction: row;
 }
 .contact-wrap {
  text-align: right;
 }
 .footer-second-line {
  flex-direction: row;
 }
 .footer-other-links {
  a {
   display: inline-block;
   margin-left: 2em;
  }
 }
 .copyright {
  margin-top: 4px;
 }
}