// IMPORTS

// TEMPLATE

.services-layout {
 max-width: 100%;
 padding: 0px ;
}

.services-section { 
 h2 {
  margin-bottom: 15px;
  padding-bottom: 5px;
 }

 &.-seo {
  h2 {
   display: inline-block;
   border-bottom: 4px solid $brand-2;
  }
 }

 &.-ads {
  h2 {
   display: inline-block;
   border-bottom: 4px solid $brand-3;
  }
 }

 &.-strategy {
  h2 {
   display: inline-block;
   border-bottom: 4px solid $brand-4;
  }
 }
}

.services-title {
 display: flex;
 // justify-content: center;
 align-items: center;
 width: 100%;
 padding: 30px 72px 60px;
 flex-direction: column;
}

.services-section-cards {
 max-width: 1200px;
 margin: auto;
 padding: 0px 20px;
 display: flex;
 flex-direction: column;
 align-items: center;
 gap: 35px;

 .card {
  width: 280px;
  min-height: 450px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 40px;
  color: white;

  &:hover,&:focus {
   text-decoration: none;
   box-shadow: 3px 3px 10px 2px rgba(0,0,0,0.4);
   cursor: pointer;
  }

  .title-card {
   display: flex;
   flex-direction: column;
   align-items: center;
  }

  h3 {
   text-align: center;
   line-height: 1.2em;
   font-size: 28px;
   font-family: $font-bold;
   margin-top: 25px;
  }

  svg {
   width: 70px;
   height: auto;
   margin-top: 25px;
  }

  p {
   font-family: $font-light;
   text-align: center;
   margin: 25px;
  }

  .discover {
   font-family: $font-bold;
  }
  
  &.-seo {
   background-color: $brand-2;
  }
  &.-ads {
   background-color: $brand-3;
  }
  &.-strategy {
   background-color: $brand-4;
  }
 }
}

@media screen and (min-width: 600px) {
 .services-section-cards {
  display: grid;
  place-items: center;
  grid-template-columns: 1fr 1fr;
 }
}

@media screen and (min-width: 900px) {
 .services-section-cards {
  grid-template-columns: 1fr 1fr 1fr;
 }
}