/* BASE STYLES */
html {box-sizing: border-box;height:100%;width:100%;}
*, *:before, *:after {box-sizing: inherit;}

body {
 position: relative;
 height: 100%;
 font-family: $font-regular;
 font-size: $font-size-base;
 z-index: 1;
 color: $gray-dark;
}

b, strong {
 font-family: $font-bold;
}

a {
 color: $brand-1;
 text-decoration: none;
 font-family: $font-bold;

 &:hover, &:focus {
  text-decoration: none;
 }
}

/* MISC */
svg {
 display: inline-block;
 width: 1em;
 height: 1em;
 fill: currentColor;
}

main {
 width: 100%;
 max-width: $max-width-normal-section;
 margin: auto;
 padding: 2em 20px 0 20px;

 h1 {
  margin-top: 0.2em;
  font-size: 1.8em;
  font-family: $font-bold;
  line-height: 1.2em;
 }
 
 h2 {
  margin-top: 1.4em;
  font-size: 1.4em;
  font-family: $font-bold;
  line-height: 1.2em;
 }

 p {
  margin-top: 0.6em;
  line-height: 1.6em;
  text-align: justify;
 }
}

@media screen and (min-width: 600px) {
 main h1 {
  font-size: 2.4em;
 }
}

.page-type {
 margin-top: 0;
 text-transform: uppercase;
 // color: $brand;
 // font-weight: 700;
}

.page-info {
 margin: 10px 0 0 2px;
 display: flex;
 // border-left: 3px solid $color-base;
 padding: 0.4em 0 0.4em 1em;
 max-width: 400px;

 & > div {
  flex: 1;

  span {
   display: block;
   font-size: 0.8em;
  }
  span:last-of-type {
   // font-weight: 700;
   margin-top: 0.3em;
  }
 }
}

.post-img {
 position: relative;
 margin-top: 1.6em;
 height: 260px;
 width: 100%;
 background: url("../img/post-image-mobile.jpg") center center;
 background-size: cover;

 span {
  position: absolute;
  left: 0; bottom: 0;
  background-color: rgba(0,0,0,0.4);
  color: #fff;
  padding: 0.6em 1.2em;
  font-size: 12px;
 }
}

@media screen and (min-width: 600px) {
 .post-img {
  background: url("../img/post-image-desktop.jpg") no-repeat center center;
  background-size: cover;
 }
}

.layout2-1 {
 display: flex;
 margin-top: 1.4em;

 article {
  flex: 2;
 }
 aside {
  display: none; //media queries
  flex: 1;
 }
}

@media screen and (min-width: 750px) {
 .layout2-1 {
  aside {display: block}
 }
}

.page-intro {
 & > p:first-child {
  // font-weight: 700;
  margin-top: 0;
 }
}

.template-404 {
 text-align: center;
 height: calc(100% - #{$header-height} - 112px);

 h1 {margin-top: 4em;}

 p {
  margin-top: 3em;
  text-align: center;
 }
 a {
  display: block;
  margin-top: 2em;
 }
}
