.basic-layout {
 max-width: 800px;
 padding-bottom: 6em;

 h1 {
  margin-bottom: 1em;
 }
 h3 {
  margin-top: 2em;
  font-size: 1.6em;
 }

 p {
  img {
   display: inline-block;
   vertical-align: top;
  }
 }

 ul, ol {
  margin-top: 1em;
  padding-left: 2em;

  li {
   list-style-type: disc;
   line-height: 1.8em;
  }
 }
}

.about-member {
 display: flex;
 flex-direction: column;
}

.about-desc {
 flex: 1;
 padding-right: 0px;
}

.about-img {
 flex: 0 1 auto;
 text-align: center;
}

.about-img .img-frame {
 display: inline-block;
 margin: 20px auto 0 auto;
 height: 100px;
 width: 100px;
 border-radius: 50%;
 overflow: hidden;

 img {
  height: 100%;
  width: auto;
 }
}

@media screen and (min-width: 500px) {
 .about-member {
  flex-direction: row;
 }
 .about-img .img-frame {
  margin: 20px 0 0 1em;
 }
 .about-desc {
  padding-right: 40px;
 }
}