/* IMPORTS */

/* CONFIG */
$max-width-single: 1100px;

.single-layout {
 padding-top: 0;
 max-width: $max-width-single;
}

.single-post-wrap {
 display: flex;
 flex-direction: column;
 margin-top: 1em;

 aside {
  flex: 1;
 }
 article {
  flex: 1;
  padding-bottom: 6em;
 }
}

.single-post-wrap article {
 h1 {
  margin-top: 0;
 }

 h2 {
  margin-top: 1.3em;
  font-size: 1.7em;
 }

 h3 {
  margin-top: 1.2em;
  font-family: $font-light;
  font-size: 1.4em;
  line-height: 1.3em;
 }
 /* Lists */
 ul, ol {
  margin-top: 1em;
  margin-bottom: 1.4em;
  li {
   margin: 0.6em 0 0 3em;
   line-height: 1.8em;
  }
 }
 ul {
  li::before {
   content: "•"; 
   color: $brand-1;
   display: inline-block;
   width: 1em;
   margin-left: -1em; 
   font-size: 1em;
  }
 }
 ol {
  counter-reset: li;
  li::before {content: "."counter(li); color: $brand-1;
   display: inline-block; width: 1.2em;
   margin-left: -1.7em; margin-right: 0.5em; 
   text-align: right; direction: rtl;
  }
  li {counter-increment: li;}
 }

 blockquote {
  margin-top: 2em;
  border-left: 4px solid $brand-1;
  background-color: $gray-light;
  padding: 1.2em 1.2em 1.2em 2em;

  p {margin: 0;}
 }
}

article {
 img {
  margin-top: 1em;
  width: 100%;
  height: auto;
 }
}

@media screen and (min-width: 800px) {
 .single-post-wrap {
  flex-direction: row;
  margin-top: 3em;

  aside {
   flex: 0 0 260px;
  }
 }
}

