.summary {
 position: sticky;
 top: 20px;

 span {
  display: block;
  color: $brand-1;
  text-transform: uppercase;
  font-family: $font-bold;
  font-size: 1.2em;
 }
 ul {
  padding: 0.8em 3em 0.6em 1.4em;

  li {
   position: relative;
   margin-bottom: 0.6em;
   line-height: 1.2em;

   a {
    color: $gray-dark;
    font-family: $font-regular;
    font-size: 16px;

    &:hover, &:focus {
     text-decoration: none;
     color: $brand-1-p20;
    }
   }

   &:before {
    $arrow-size: 4px;

    position: absolute;
    content: "";
    width: 0; 
    height: 0; 
    border-top: #{$arrow-size} solid transparent;
    border-bottom: #{$arrow-size} solid transparent;
    border-right: #{$arrow-size} solid transparent;
    border-left: calc(#{$arrow-size} + 3px) solid $brand-1;
    top: 4px;
    left: -0.9em;
   }
  }
 }
}

.summary.single {
 margin-top: 0.4em;

 span {
  font-size: 1em;
 }
}